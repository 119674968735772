<template>
  <span class="icon-combo">
    <ui-icon
      class="icon-combo__main"
      :icon="icon"
    />

    <ui-icon
      class="icon-combo__addition"
      :icon="iconAddition"
    />
  </span>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'icon-combo',

  components: {
    UiIcon,
  },

  props: {
    icon: {
      type: String,
      default: '',
    },

    iconAddition: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-combo {
  --main-color: #{$color-ui-default};
  --addition-color: #{$color-ui-default};

  position: relative;

  &__main {
    color: var(--main-color);
    vertical-align: middle;
    display: inline-block;
    font-size: 0.9em;
    transform: translateX(-0.1em);
  }

  &__addition {
    color: var(--addition-color);
    display: inline-block;
    position: absolute;
    font-size: 0.5em;
    top: 0.1em;
    right: -0.3em;
  }
}
</style>
