import { showError, showSuccess } from 'Utils/notifications'
import { i18n } from 'I18n/instance'

/**
 * @typedef {object} CommandArgs
 * @property {boolean} [opts.isSuccessReportSuppressed]
 * Provide true to suppress the default success report
 * @property {boolean} [opts.isFailureReportSuppressed]
 * Provide true to suppress the default failure report
 * @property {boolean} [opts.isForcedErrorThrow]
 * Provide true to throw the occurred error
 */

/**
 * Base commands class
 *
 * see the pattern:
 * https://refactoring.guru/design-patterns/command
 *
 * @interface
 */
export class Command {
  /**
   * Create a configured command
   * @param {CommandArgs} [opts]
   */
  constructor (opts) {
    this.isSuccessReportSuppressed = opts.isSuccessReportSuppressed || false
    this.isFailureReportSuppressed = opts.isFailureReportSuppressed || false
    this.isForcedErrorThrow = opts.isForcedErrorThrow || false

    this.$t = (...args) => i18n.t(...args)
  }

  /**
   * Run the command
   */
  async execute () {
    throw new SyntaxError('Not implemented')
  }

  /**
   * Reverts the `execute()`’s result
   */
  async undo () {
    throw new SyntaxError('Not implemented')
  }

  /**
   * Reports success if allowed. Automatically appends undo button if
   * implemented.
   * @param  {string} msg
   */
  _reportSuccess (msg, suppressUndo = false) {
    if (this.isSuccessReportSuppressed) return

    const isUndoImplemented = this.undo !== Command.prototype.undo
    if (isUndoImplemented && !suppressUndo) {
      showSuccess({ text: msg, undoAction: () => this.undo() })
      return
    }

    showSuccess(msg)
  }

  /**
   * Logs an error and shows a report to the user
   * @param {Error} error
   * @param {string} msg
   */
  _reportError (error, msg) {
    if (!this.isFailureReportSuppressed) {
      console.error(error)
      showError(msg)
    }

    if (this.isForcedErrorThrow) {
      throw error
    }
  }

  /**
   * Report error occurred during undo() run
   * @param {Error} error
   */
  _reportUndoError (error) {
    console.error(error)
  }
}
