/**
 * @typedef {object} translationParams
 * @property {string} [msgOrTrParams.translationId]
 * @property {object} [msgOrTrParams.translationParams]
 */

/**
 * Mimicry JSV error, so jsvLocalizeError could localize it
 * @param {string|translationParams} msgOrTrParams
 */
export function jsvMimicryError (msgOrTrParams) {
  const base = {
    keyword: '',
    params: {},
  }

  if (typeof msgOrTrParams === 'string') {
    base.translatedMsg = msgOrTrParams
    return base
  }

  if (msgOrTrParams && typeof msgOrTrParams === 'object') {
    base.translationId = msgOrTrParams.translationId || ''
    base.translationParams = msgOrTrParams.translationParams || {}
    return base
  }

  throw TypeError('Invalid msgOrTrParams type')
}
