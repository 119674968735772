<template>
  <list
    class="list-grouped"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :list-length="list.length"
    :page-size="pageSize"
    :load-more="loadMore"
    :reset-on-change="resetOnChange"
  >
    <template slot="loading-stub">
      <div class="list-grouped__items">
        <component
          class="archive-list__item"
          :is="itemSkeletonComponent"
          v-for="i in 3 * columns"
          :key="i"
        />
      </div>
    </template>

    <template slot="next-loading-stub">
      <div class="list-grouped__items">
        <component
          v-for="i in columns"
          :key="i"
          class="archive-list__item"
          :is="itemSkeletonComponent"
        />
      </div>
    </template>

    <template slot="no-list-msg-pre">
      <template v-if="hasHeadComponent">
        <component
          class="list-grouped__head"
          :is="headComponent"
          v-bind="headComponentProps"
        />
      </template>

      <div class="list-grouped__group">
        <div class="list-grouped__group-title-wrp">
          <label class="list-grouped__group-title">
            {{ $t('COMMON.LIST_GROUPING.TITLE_FORMAT', {
              groupTitle: $t('ALL_GROUP_TITLE'),
              itemsCount: 0,
            }) }}
          </label>

          <button
            class="list-grouped__actions-btn"
            @click="reloadList"
          >
            {{ $t('REFRESH_BTN') }}
          </button>
        </div>
      </div>
    </template>

    <slot
      name="no-list-msg"
      slot="no-list-msg"
    >
      {{ $t('NO_ITEMS_MSG') }}
    </slot>

    <template slot="list">
      <div class="list-grouped__list-wrp">
        <div class="list-grouped__list-inner">
          <template v-if="hasHeadComponent">
            <component
              class="list-grouped__head"
              :is="headComponent"
              v-bind="headComponentProps"
            />
          </template>

          <div
            class="list-grouped__group"
            v-for="({ title, items }, index) of listGrouped"
            :key="title"
          >
            <div class="list-grouped__group-title-wrp">
              <label class="list-grouped__group-title">
                {{ title }}
              </label>

              <div
                v-if="index === 0"
                class="list-grouped__actions"
              >
                <slot name="actions" />

                <button
                  class="list-grouped__actions-btn"
                  @click="reloadList"
                >
                  {{ $t('REFRESH_BTN') }}
                </button>
              </div>
            </div>

            <template v-if="items.length">
              <div class="list-grouped__items">
                <component
                  class="list-grouped__item"
                  :is="itemComponent"
                  :item="item"
                  v-for="(item, componentIndex) in items"
                  :key="componentIndex"
                  v-bind="itemComponentProps"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </list>
</template>

<script>
import List from 'Common/List'
import { groupByDate, groupByFirstLetter } from 'Utils/grouping'

export default {
  name: 'list-grouped',

  components: {
    List,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },

    listTotalCount: {
      type: Number,
      required: true,
    },

    groupByDateField: {
      type: String,
      default: '',
    },

    itemsCountField: {
      type: String,
      default: '',
    },

    groupByFirstLetterField: {
      type: String,
      default: '',
    },

    isLoading: {
      type: Boolean,
      required: true,
    },

    isNextLoading: {
      type: Boolean,
      required: true,
    },

    pageSize: {
      type: Number,
      required: true,
    },

    loadMore: {
      type: Function,
      required: true,
    },

    headComponent: {
      type: Object,
      default: () => ({}),
    },

    headComponentProps: {
      type: Object,
      default: () => ({}),
    },

    itemComponent: {
      type: Object,
      required: true,
    },

    itemComponentProps: {
      type: Object,
      default: () => ({}),
    },

    itemSkeletonComponent: {
      type: Object,
      required: true,
    },

    resetOnChange: {
      type: String,
      default: '',
    },

    columns: {
      type: Number,
      default: 1,
    }
  },

  computed: {
    hasHeadComponent () {
      return Object.keys(this.headComponent).length > 0
    },

    listGrouped () {
      if (this.groupByDateField) {
        return groupByDate(
          this.list,
          this.groupByDateField,
          this.itemsCountField
        )
      }

      if (this.groupByFirstLetterField) {
        return groupByFirstLetter(
          this.list,
          this.groupByFirstLetterField,
        )
      }

      const title = this.listTotalCount > 0
        ? this.$t('COMMON.LIST_GROUPING.TITLE_FORMAT', {
          groupTitle: this.$t('ALL_GROUP_TITLE'),
          itemsCount: this.listTotalCount,
        })
        : this.$t('ALL_GROUP_TITLE')
      return [
        {
          title,
          items: this.list
        },
      ]
    },
  },

  methods: {
    reloadList () {
      this.$emit('update-list-ask')
    },
  },
}
</script>

<style lang="scss" scoped>
.list-grouped {
  --list-padding-side: 3em;

  /deep/ .list__items-wrp {
    gap: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__group {
    &-title-wrp {
      padding: 3em var(--list-padding-side) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      display: block;
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.6em;

    &-btn {
      background: none;
      border: none;
      font-size: 1.2em;
      font-weight: 500;
      line-height: 1.5;
      color: $color-sys-info;
      transition: color 150ms;

      &:hover {
        color: rgba($color-sys-info, 0.8);
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    padding: 0 var(--list-padding-side);
  }

  &__head {
    padding: 2em var(--list-padding-side) 0;
    margin-bottom: -1em;
    min-width: 1200px;
  }

  &__list {
    &-wrp {
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-inner {
      min-width: 1200px;
      flex: 1;
    }
  }

  &__no-items-msg {
    @include respond(margin-top, 2.5em, 2em, 2em 1.5em);

    color: $color-ui-secondary;
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;
    padding: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "NO_ITEMS_MSG": "No items here yet",
    "ALL_GROUP_TITLE": "All",
    "REFRESH_BTN": "Refresh"
  },
  "ka": {
    "NO_ITEMS_MSG": "სია ცარიელია",
    "ALL_GROUP_TITLE": "ყველა",
    "REFRESH_BTN": "განახლება"
  },
  "ru": {
    "NO_ITEMS_MSG": "Нет материалов для отображения",
    "ALL_GROUP_TITLE": "Все",
    "REFRESH_BTN": "Обновить"
  },
  "uk": {
    "NO_ITEMS_MSG": "Нема матеріалів для відображення",
    "ALL_GROUP_TITLE": "Усі",
    "REFRESH_BTN": "Оновити"
  }
}
</i18n>
