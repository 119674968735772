import VueScrollTo from 'vue-scrollto'

export function scrollTo (selector, container) {
  setTimeout(
    _ => VueScrollTo.scrollTo(selector, { container, offset: -200 }),
    50,
  )
}

export function scrollToError (container) {
  scrollTo('[is-error=true]', container)
}

export function scrollToErrorInModal () {
  scrollToError('.ui-modal__content')
}
